import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import style from "!!file-loader!@paljs/admin/style.css";

const ScopeAdminStyle = ({ children }) => {
  return (
    <div >
      <style scoped>
        {`@import url(${style})`}
      </style>
      {children}
    </div>
  )
}

export default ScopeAdminStyle;