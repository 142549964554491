import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';

export const LIST_ITEMS = [
  {
    path: '/admin/User',
    title: 'Users',
    Icon: PersonIcon,
  },
  {
    path: '/admin/VaccinationType',
    title: 'Vaccination types',
    Icon: FormatListNumbered,
  },
  {
    path: '/admin/Vaccination',
    title: 'Vaccinations',
    Icon: LocalHospitalIcon,
  },
  {
    path: '/admin/MeningitisWaiver',
    title: 'Meningitis waivers',
  },
  {
    path: '/admin/GeneralHealthQuestion',
    title: 'General health questions',
  },
  {
    path: '/admin/GeneralHealthAnswerOption',
    title: 'General health answer options',
  },
  {
    path: '/admin/GeneralHealthAnswer',
    title: 'General health answers',
  },
  {
    path: '/admin/Language',
    title: 'Language',
  },
  {
    path: '/admin/Attachment',
    title: 'Attachment',
  },
];